import React from "react"
import styled, { ThemeProvider } from "styled-components"
import { theme } from "../styledComponents/theme"
import { GlobalStyle } from "../styledComponents/globalStyles"
import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"
import IDHelmet from "../components/IDHelmet/IDHelmet"
import SocialsImage from "../images/id-socials.png"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faLongArrowAltLeft,
  faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons"

import Header, { headerHeight } from "../components/UI/Header/Header"
//import Footer from '../components/UI/Footer/Footer';
import PageSection from "../components/PageSection/PageSection"
import FlexContainer from "../components/UI/FlexContainer/FlexContainer"
import Heading from "../components/UI/Heading/Heading"
import Paragraph from "../components/UI/Paragraph/Paragraph"

const BIContainerStyled = styled.div`
  margin-top: ${headerHeight};
  width: 100%;
  position: relative;
  height: 70vh;

  @media ${props => props.theme.media.tablet} {
    width: 100%;
    height: 400px;
  }
`

const ImageContainerStyled = styled.div`
  width: 50%;
  padding: 0 15px;

  @media ${props => props.theme.media.tablet} {
    width: 90%;
  }
`

const BIStyled = styled(BackgroundImage)`
  width: 100%;
  height: 100%;
`

const BIOpacityFilter = styled.div`
  width: 100%;
  height: 100%;
  background: ${props => props.theme.colors.opacityFilterDark};
`

const CustomFlexContainer = styled(FlexContainer)`
  height: 100%;
  padding: 0;
`

const HalfContainer = styled(FlexContainer)`
  height: 100%;
  width: 50%;
  padding: 0 15px;
`

const ParagraphStyled = styled(Paragraph)`
  padding: 5px;
  text-align: center;
`

const Hero = styled.div`
  h3 {
    font-size: 4rem;
  }
  text-shadow: 2px 2px 10px ${props => props.theme.colors.darkFont};

  padding: 0 10rem;
  p {
    font-size: 1.2rem;
    color: ${props => props.theme.colors.lightFont};
  }
`

// this is the CSS of ParagraphStyled and Paragraph combined so that it can be rendered as a list item
const ListParagraph = styled.li`
  list-style-position: outside;
  padding: 5px;
  text-align: left;
`

const ListStyled = styled.ul`
  list-style-position: inside;
  list-style-type: disc;
  width: 100%;
`

const Emphasis = styled.strong`
  font-size: 1.2em;
  font-weight: 700;
  padding: 10px 5px;
`

const LinkIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
  color: ${props => props.theme.colors.darkFont};
`

const LinkText = styled.span`
  padding-right: 10px;
  font-weight: 700;
  color: ${props => props.theme.colors.darkFont};
`

const LinkWrapper = styled.div`
  background-color: ${props => props.theme.colors.lightFont};
  border-radius: 50px;
  padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`

const BackLink = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px;
  margin: 20px;
  border-radius: 100px;
  font-size: 0.8rem;
  background: ${props => props.theme.colors.opacitySecondaryAccent};
  &:hover {
    background: ${props => props.theme.colors.lightFont};
  }
`

const Portfolio = props => {
  const info = props.data.markdownRemark

  const s1Text = info.frontmatter.S1Text.map((line, index) => (
    <ParagraphStyled key={index}>{line}</ParagraphStyled>
  ))

  const s2Text = info.frontmatter.S2Text.map((line, index) => (
    <ParagraphStyled key={index}>{line}</ParagraphStyled>
  ))

  const features = info.frontmatter.featureList.map((feature, index) => (
    <ListParagraph key={index}>{feature}</ListParagraph>
  ))

  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle />

        <IDHelmet
          title="Issy Dennis - Frontend Developer"
          description={`Frontend developer based in Cardiff, specialising in React and Typescript. See examples of my work - ${info.frontmatter.title}`}
          image={SocialsImage}
          url={
            "https://www.issydennis.com" + props.location.pathname.slice(0, -1)
          }
        />

        <Header svgBackground />

        {/* Section 1 */}

        <BIContainerStyled>
          <BIStyled fluid={info.frontmatter.titleImage.childImageSharp.fluid}>
            <BIOpacityFilter>
              <CustomFlexContainer
                column
                alignItems="center"
                justifyContent="center"
              >
                <BackLink>
                  <Link to="/#work">
                    <FontAwesomeIcon icon={faLongArrowAltLeft} />
                    <Emphasis>Go back</Emphasis>
                  </Link>
                </BackLink>
                <Hero>
                  <Heading textColor={props => props.theme.colors.lightFont}>
                    {info.frontmatter.title}{" "}
                  </Heading>
                  {s1Text}
                </Hero>
              </CustomFlexContainer>
            </BIOpacityFilter>
          </BIStyled>
        </BIContainerStyled>

        {/* Section 2 */}
        <PageSection background={props => props.theme.colors.secondaryAccent}>
          <FlexContainer column>
            <CustomFlexContainer column>
              <Heading textColor={props => props.theme.colors.darkFont}>
                {info.frontmatter.S2Heading}
              </Heading>
              {s2Text}

              {info.frontmatter.websiteLink && (
                <a
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  href={info.frontmatter.websiteLink}
                >
                  <LinkWrapper>
                    <LinkIcon icon={faExternalLinkAlt} size="2x" />
                    <LinkText>View the website</LinkText>
                  </LinkWrapper>
                </a>
              )}
            </CustomFlexContainer>
          </FlexContainer>
        </PageSection>

        {/* Section 3 */}
        <PageSection background={props => props.theme.colors.primaryAccent}>
          <FlexContainer justifyContent="space-between">
            <HalfContainer column>
              <Heading textColor={props => props.theme.colors.darkFont}>
                Here's how it's done...
              </Heading>
              <ListStyled>{features}</ListStyled>
            </HalfContainer>

            <ImageContainerStyled>
              <Img
                fluid={info.frontmatter.showcaseImage.childImageSharp.fluid}
              />
            </ImageContainerStyled>
          </FlexContainer>
        </PageSection>
      </>
    </ThemeProvider>
  )
}

export default Portfolio

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      frontmatter {
        title
        S1Heading
        S1Text
        S2Heading
        S2Text
        websiteLink
        featureList
        titleImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        showcaseImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
